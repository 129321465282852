<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="210" @getdata="gettable" @changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showeidt(scope.row)" type="success" size="mini">详情</el-button>
							<el-button v-show="scope.row.apply_status==0" @click="pass(scope.row)" type="primary" size="mini">通过</el-button>
							<el-button v-show="scope.row.apply_status==0" @click="bohui(scope.row)" type="danger" size="mini">驳回</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>

		<detail ref="mydetail"></detail>



		<!--签到二维码-->
		<el-dialog title="签到二维码" :visible.sync="showcode" width="400px">
			<div style="width: 100%;display: flex;align-items: center;justify-content: center;">
				<vue-qr :logoSrc="imageLogo" :text=qr_url :size="300" :margin="5" colorDark="black"
					colorLight="white"></vue-qr>
			</div>
			<span slot="footer" class="dialog-footer"
				style="display: flex;align-items: center;justify-content: flex-end;">
				<el-button @click="showcode = false">关 闭</el-button>
				<!-- <el-button type="primary" @click="postqingjia">确 定</el-button> -->
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import detail from './detail.vue'
	import vueQr from "vue-qr";
	export default {
		components: {
			detail,
			vueQr
		},
		data() {
			return {
				searchinput: '',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},
					{
						prop: 'name',
						name: '活动名称',
						width: ''
					},
					{
						prop: 'classify.name',
						name: '活动类型',
						width: '150'
					},
					{
						prop: 'apply_status',
						name: '状态',
						width: '150',
						type: 'lvzsbtype'
					},
					{
						prop: 'start_time',
						name: '活动开始时间',
						width: '180'
					},
					{
						prop: 'updatetime',
						name: '提交时间',
						width: '180'
					}
				],
				tableData: [],
				showcode: false,
				qr_url: '',
				imageLogo: require("@/assets/logo.png"),
			}
		},
		mounted() {
			this.gettable()
		},
		methods: {
			editerow(row) {
				this.$router.push({
					path: '/system/activity_edit',
					query: {
						id: row.id
					}
				})
			},
			pass(row) {
				this.$confirm('请确认是否审核通过?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/user_resumption/pass',
						params: {
							id: row.id
						}
					}).then((res) => {
						this.$message.success('操作成功')
						this.gettable()
					})
				}).catch(() => {

				})
			},
			bohui(row) {
				this.$prompt('请输入驳回原因', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /\S/,
					inputErrorMessage: '请输入驳回理由'
				}).then(({
					value
				}) => {
					this.$post({
						url: '/api/user_resumption/refund',
						params: {
							id: row.id,
							refund_text:value
						}
					}).then((res) => {
						this.$message.success('操作成功')
						this.gettable()
					})
				}).catch(() => {
					
				});
			},
			showeidt(row) {
				this.$post({
					url: '/api/user_resumption/details',
					params: {
						id: row.id
					}
				}).then((res) => {
					console.log(res)
					this.$refs.mydetail.detail = res
					this.$refs.mydetail.dialogVisible = true
				})
			},
			changswitch(row) {
				this.$post({
					url: '/api/room/edit',
					params: row
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
				})
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/user_resumption/index',
					params: {
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew() {
				this.$router.push('/system/activity_add')
			},
			showdate(row) {
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'lvzhi_list.scss';
</style>